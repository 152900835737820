.Home {
  background-color: #282c34;
  min-height: 100vh;
}

h1 {
  text-align: center;
  font-size: 50px;
  color: white;
}

h4 {
  text-align: center;
  font-size: 30px;
  color: white;
  margin: .5rem;
}

.sortHeader {
  text-align: center;
  font-size: x-large;
  color: white;
  padding: 5px;
}

.sortTags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  background-color: #1d1f24;
  padding: 15px 1%;
  margin: 0px 20%;
  border-radius: 30px;
  flex-wrap: wrap;
}

.sortButton {
  background-color: rgb(73, 73, 73);
  border-radius: 25px;
  padding: 6px 15px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.projectContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px 10px;
}